import React, { useState, useEffect } from "react";
import { TokenData } from "../interfaces/TokenData";
import { getTokenData } from '../services/tokenDataService';
import windowsIcon from '../assets/img/windows-0.png';
import soundOnIcon from '../assets/img/icons/loudspeaker_rays-0.png';

interface TemplateProps {
    children: React.ReactNode;
}

const Layout: React.FC<TemplateProps> = ({ children }) => {
    const [tokenData, setTokenData] = useState<TokenData>();    
    const [clockLabel, setClockLabel] = useState<string>("");
    useEffect(() => {
        const fetchData = async () => {
            setTokenData(getTokenData());
            const updateClock = () => {
                const now = new Date();
                setClockLabel(now.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true }));
            };
            updateClock(); // Set initial time
            const clockInterval = setInterval(updateClock, 1000); // Update every minute

            return () => clearInterval(clockInterval); // Cleanup interval on component unmount
        }
        fetchData();
        return () => { }
    }, []);

    if (!tokenData) return (<div>'Loading...'</div>)

    return (
        <div className="desktop">
                {children}

                {/* taskbar */}
                <div className="footer window" style={{ fontSize: "8pt", margin: "0" }}>
                    <button className="window" style={{ width: "57px", minWidth: "48px", margin: "0", display: "flex", alignItems: "center", float: "left" }}>
                        <img src={windowsIcon} style={{ width: "20px", marginRight: "2px" }}></img>
                        <span style={{ margin: "0", fontWeight: "bold" }}>Start</span>
                    </button>
                    <fieldset className="taskbar-clock">
                        <div style={{ width: "100%", margin: "0", display: "flex", alignItems: "center", float: "right" }}>
                            <img src={soundOnIcon} style={{ width: "20px", marginRight: "2px" }}></img>
                            <span style={{ margin: "0", fontWeight: "bold" }}>{clockLabel}</span>
                        </div>
                    </fieldset>
                    
                </div>
        </div >
    );
};

export default Layout;
